.card-information {
  background-color: transparent;
  border-radius: 0;
  color: transparent;
  //max-width: 300px;
  margin: 0;
  min-height: 285px;
  //padding: 30px;

  & label {
    margin-top: 15px;
  }

  & .card-title {
    color: #B33C12;
    text-align: center;
  }

  & .btn {
    margin-top: 30px;
  }

  & .forgot {
    text-align: center;
  }

  .input-group-text {
    border: 1px solid $white-color;
  }
}